<template>
    <div class="text-center">
        <h3 class="m-t-40 fs23">{{ $t("CANCELLATION.CANCEL_FREE_CHARGE_LOADING") }}</h3>
        <h1 class="m-t-30">{{ $t("CANCELLATION.CANCELLATION_FAILED") }}</h1>
        <h3 class="m-t-30 fs23">הביטול נכשל מפני שהיה שימוש בכרטיס</h3>
        <h3 class="m-t-20 fs23">הודעת שגיאה: דלךגכמדךגל</h3>
        <button class="primary-btn result-btn">
            {{ $t("CANCELLATION.CLOSED") }}
        </button>
    </div>
</template>

<script>
export default {
    name: "CancellationFaild",
    mounted() {
        document.getElementsByTagName("html")[0].style.background = "#FFA5A5";
    },
}
</script>

<style lang="scss" scoped>
.result-btn {
    margin: 0 auto;
    margin-top: 40px;
    max-width: 400px;
}
</style>